<template>
	<div>
		<form class="container">
			<a-tutorial-video v-if="!isAnyProductAndProductPeriodSelected" videoSrc="https://www.youtube.com/embed/tgbNymZ7vqY"></a-tutorial-video>
			<a-order-minimum-warning v-if="orderTotalDaysValidation" />
			<div class="row">
				<div class="col-md-8">
					<b-tabs id="tabs" v-model="activeTab" card>
						<b-tab class="px-0" v-for="(orderRegistration, i) in uniqueOrderRegistrations" :key="i">
							<template #title>
								<div class="d-flex align-items-center">
									Študent {{ i + 1 }}
									<b-button v-if="uniqueOrderRegistrations.length > 1" class="float-right z-remove-icon" size="sm" variant="link" @click="removeStudent(orderRegistration.fe_custom_data.student_index)">
										<b-icon icon="x-circle"></b-icon>
									</b-button>
								</div>
							</template>
							<a-order-product-select :order-registration="orderRegistration" @product-selected="onProductForStudentChanged" @product-period-selected="onProductPeriodForStudentChanged($event, orderRegistration, i)" @faculty-selected="onFacultyChanged" />

							<div id="select-days" v-if="orderRegistration.product_period_id">
								<h4></h4>
								<h5 v-if="isGamecraftOnSchools">V kalendári sú uvedené lekcie do konca školského roka, vyberte si ľubovoľný počet lekcií, minimum je 8.</h5>
								<h4 v-else-if="isCourse">Vyberte si ktoré dni v týždni sa chcete zúčastniť lekcie:</h4>
								<p v-else-if="isAcademy">Prednášky budú vždy v pondelok a stredu cca od 18:00- 20:00 hod (zmena programu vyhradená) <a href="../podmienky/podmienky-sgd-academy-2022.pdf">viď obchodné podmienky</a></p>
								<h4 v-else-if="isLevelUp">Dni v týždni, kedy prebieha kurz:</h4>
								<h4 v-else>Dni v týždni, kedy prebieha tábor:</h4>

								<a-product-period-setting v-if="calOrderRegistration(orderRegistration).product_period.product" :order-registration="calOrderRegistration(orderRegistration)" :readonly="!calOrderRegistration(orderRegistration).product_period.is_allowed_select_day" @day-selected="onRegistratonDatesChanged($event, orderRegistration)" @preferred-days-changed="onPreferredDaysChanged($event, orderRegistration)" />
							</div>
						</b-tab>
						<template #tabs-end v-if="!isGamecraftOnSchools">
							<b-nav-item v-if="uniqueOrderRegistrations.length < 5" href="#" role="presentation" class="a-add-student-button" @click.prevent="addStudent">
								<b>+ Pridať študenta</b>
							</b-nav-item>
						</template>
					</b-tabs>
				</div>

				<div class="col-md-4" :class="{ 'price-loading': orderPriceLoading }" v-if="isAnyProductAndProductPeriodSelected">
					<div class="sticky-top sticky-offset">
						<span v-if="orderPriceLoading" class="spinner-border spinner-border-sm price-spinner" role="status" aria-hidden="true"></span>

						<div class="mb-3 text-small">
							<a-order-student-overview-collapse :class="{ active: activeTab == i }" v-for="(registration, i) in uniqueOrderRegistrationsWithPrices" :key="i" :index="i + 1" :order-registration="registration" />
						</div>

						<b-button v-if="uniqueOrderRegistrations.length < 5" class="z-submit-btn mb-3 w-100" :disabled="isLoading" variant="success" size="sm" @click="addStudent(true)">+ Pridať študenta</b-button>

						<a-order-prices />
						<a-order-discount class="mb-2" />

						<a-order-gift :isGiftVoucher="orderIsGiftVoucher" />

						<b-button class="z-submit-btn mt-2 w-100" :disabled="isLoading" variant="success" @click="onNext">
							<span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							Pokračovať
						</b-button>

						<b-button class="mt-2" block :disabled="isLoading" variant="outline-dark" size="sm" @click="onReset">
							<b-icon icon="x"></b-icon>
							Resetovať prihlášku
						</b-button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import debounce from 'debounce'
import productUtils from '@/plugins/app/_utils/product.util.js'

export default {
	components: {
		'a-order-student-overview-collapse': () => import('@/plugins/app/order/components/a-order-student-overview-collapse.vue'),
		'a-product-period-setting': () => import('@/plugins/app/_features/product-order/a-product-period-setting.vue'),
		'a-order-discount': () => import('./components/a-order-discount.vue'),
		'a-order-minimum-warning': () => import('@/plugins/app/order/components/a-order-minimum-warning.vue'),
		'a-order-prices': () => import('./components/a-order-prices.vue'),
		'a-order-product-select': () => import('@/plugins/app/order/components/a-order-product-select.vue'),
		'a-order-gift': () => import('./components/a-order-gift.vue'),
		'a-tutorial-video': () => import('@/plugins/app/order/components/a-tutorial-video.vue')
	},

	data() {
		return {
			activeTab: 1,
			isLoading: false,
			email: localStorage.getItem('hemisfera_preregEmail'),
			consent: false
		}
	},

	computed: {
		...mapGetters('wAuth', ['isLoggedIn']),
		...mapGetters('order', {
			orderRegistrationsForStudentIndex: 'orderRegistrationsForStudentIndex',
			orderCalendarOrderRegistration: 'calendarOrderRegistration',
			orderFacultySelectionValidation: 'facultySelectionValidation',
			orderTotalDaysValidation: 'totalDaysValidation'
		}),
		...mapFields('order', ['orderRegistrations']),
		...mapState('order', {
			orderPrice: 'orderPrice',
			orderIsGiftVoucher: 'isGiftVoucher',
			orderPriceLoading: 'priceLoading',
			orderIsPartialPayment: 'isPartialPayment',
			orderPartialPayments: 'partialPayments'
		}),

		isAnyProductAndProductPeriodSelected() {
			return this.orderRegistrations.some((or) => or.product_period && or.product_period.product)
		},

		type() {
			return this.$route.params.type
		},

		isGamecraftOnSchools() {
			return this.type == 'gamecraft-na-skolach' || this.type == 'gamecraft-na-skolach-60min'
		},

		uniqueOrderRegistrations() {
			return this.orderRegistrations.reduce((unique, or) => {
				if (unique.some((o) => o.fe_custom_data.student_index == or.fe_custom_data.student_index)) return unique

				unique.push(or)

				return unique
			}, [])
		},

		uniqueOrderRegistrationsWithPrices() {
			if (!this.orderPrice.order_once_pay) return []

			return this.orderPrice.order_once_pay.order_registrations.reduce((unique, or) => {
				if (unique.some((o) => o.fe_custom_data.student_index == or.fe_custom_data.student_index)) return unique

				unique.push(or)

				return unique
			}, [])
		},
		isCourse() {
			return productUtils.isCourse(this.type)
		},
		isCamp() {
			return productUtils.isCamp(this.type)
		},
		isLevelUp() {
			return productUtils.isLevelUp(this.type)
		},
		isAcademy() {
			return productUtils.isAcademy(this.type)
		}
	},

	async mounted() {
		this._trackDataLayerEvent()

		try {
			const orderId = localStorage.getItem('hemisfera_orderId')
			if (orderId) {
				// refresh token before creating an order is user is logged in
				if (this.isLoggedIn) await this.$store.dispatch('wAuth/userInfo')
				await this.$store.dispatch('order/getOrder')
			}
		} catch (e) {
			this.$wToast.error(e)
		}
	},

	methods: {
		onProductForStudentChanged(product) {
			this.$store.commit('order/pricesRetrieved', product)
		},

		onProductPeriodForStudentChanged(productPeriod, orderRegistration) {
			const feStudentIndex = orderRegistration.fe_custom_data.student_index || Math.floor(Math.random() * 1000000000000)
			const studentsOrderRegistrations = this.orderRegistrationsForStudentIndex(orderRegistration.fe_custom_data.student_index)

			const currentStudentIndex = orderRegistration.fe_custom_data.student_index
			const orWithSelectedPeriodId = this.orderRegistrations.findIndex((or) => or.fe_custom_data.student_index == currentStudentIndex && or.product_period_id == productPeriod.id)

			// prevent removing last product period from student
			if (studentsOrderRegistrations.length == 1 && orWithSelectedPeriodId > -1) return

			// remove turnus
			if (this.type == 'gamecamp' && orWithSelectedPeriodId > -1) return this.orderRegistrations.splice(orWithSelectedPeriodId, 1)

			// add new turnus
			if (this.type == 'gamecamp' && orderRegistration.product_period_id) return this._createNewOrderRegistration(productPeriod, orderRegistration, feStudentIndex)

			// attach product period to student (to corresponding order registration)
			orderRegistration.fe_custom_data.student_index = feStudentIndex
			// if product is not allowed to select days, then preselect all possible dates
			orderRegistration.order_registration_dates = productPeriod.is_allowed_select_day == 0 ? productPeriod.available_dates.map((d) => ({ date: d })) : []
			orderRegistration.product_period = productPeriod
			orderRegistration.product_period_id = productPeriod.id

			this._scrollToId(orderRegistration.product_period.faculties.length ? 'select-faculty' : 'select-days')
		},

		onFacultyChanged({ studentIndex, productPeriodId, faculty }) {
			this.orderRegistrations.forEach((order) => {
				if (order.fe_custom_data.student_index == studentIndex) order.faculty_id = faculty.id
			})
			this._scrollToId('select-days')
		},

		onRegistratonDatesChanged(orderRegistrationDates, orderRegistration) {
			orderRegistration.order_registration_dates = orderRegistrationDates
		},

		onPreferredDaysChanged(preferredDays, orderRegistration) {
			orderRegistration.fe_custom_data.preferred_days = preferredDays
		},

		onReset() {
			const confirmed = confirm('Naozaj chcete zresetovať prihlášku?')

			if (confirmed) this.$store.commit('order/reseted', true)
		},

		async onNext() {
			if (this.orderTotalDaysValidation) {
				const elem = document.getElementById('requiredDays')
				if (elem.classList) elem.classList.add('fade-in')
				setTimeout(function() {
					if (elem.classList) elem.classList.remove('fade-in')
				}, 5000)
				elem.scrollIntoView()
				return
			}

			if (this.email && !this.consent) return this.$wToast.error('Pred pokračovaním musíte súhlasiť so spracovaním osobných údajov ak ste zadali email.')

			const re = /\S+@\S+\.\S+/
			if (this.email && this.consent && !re.test(this.email)) return this.$wToast.error('Zadaný email nie je vo vhodnom tvare.')

			if (this.orderIsPartialPayment && !this.orderPartialPayments) return this.$wToast.error('Pred pokračovaním musíte zvoliť počet mesačných platieb.')

			if (this.orderIsPartialPayment === null) return this.$wToast.error('Pred pokračovaním musíte zvoliť typ platby.')

			if (this.orderFacultySelectionValidation) {
        let message = 'Pred pokračovaním musíte zvoliť fakultu.'
        if (this.type == 'vytvor-si-hru') message = 'Zvoľte alternatívny deň v týždni.'
        return this.$wToast.error(message)
      }

			this.isLoading = true
			try {
				const orderId = localStorage.getItem('hemisfera_orderId')

				try {
					// refresh token before creating an order is user is logged in
					if (this.isLoggedIn) await this.$store.dispatch('wAuth/userInfo')

					if (orderId) await this.$store.dispatch('order/updateOrder', { email: this.email || undefined })
					else await this.$store.dispatch('order/createOrder', { email: this.email || undefined })
				} catch (e) {
					this.$wToast.error(e)
				}

				localStorage.setItem('hemisfera_preregEmail', this.email ? this.email : '')
				if (this.isLoggedIn) this.$router.push({ name: 'StudentsInformation' })
				else this.$router.push({ name: 'Registration' })
			} catch (e) {
				alert(e)
			} finally {
				this.isLoading = false
			}
		},

		addStudent(scrollTop = false) {
			this.orderRegistrations.push({
				order_registration_dates: [],
				student_id: null,
				student: {},
				product_period: null,
				product_period_id: null,
				faculty_id: null,
				fe_custom_data: { student_index: Math.floor(Math.random() * 1000000000000), preferred_days: [] }
			})

			if (scrollTop) window.scrollTo(0, 0)

			setTimeout(() => (this.activeTab = this.orderRegistrations.length - 1), 100)
		},

		removeStudent(studentIndex) {
			this.orderRegistrations = this.orderRegistrations.filter((or) => or.fe_custom_data.student_index !== studentIndex)
		},

		_createNewOrderRegistration(productPeriod, currentOrderRegistration, index) {
			// attach product period to student (to corresponding order registration)
			currentOrderRegistration.fe_custom_data = { student_index: index }
			return this.orderRegistrations.push({
				order_registration_dates: productPeriod.available_dates.map((d) => ({ date: d })),
				fe_custom_data: { student_index: index, preferred_days: [] },
				student: {},
				product_period: productPeriod,
				product_period_id: productPeriod.id,
				faculty_id: null
			})
		},

		calOrderRegistration(orderRegistration) {
			return this.orderCalendarOrderRegistration(orderRegistration.fe_custom_data.student_index, this.type)
		},

		_scrollToId(id) {
			this.$nextTick(() => {
				setTimeout(() => document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth', alignToTop: true }), 100)
			})
		},

		_trackDataLayerEvent() {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'begin_checkout',
				pageTitle: 'Prihláška'
			})
		}
	},

	watch: {
		orderRegistrations: {
			deep: true,
			handler: async function(newValue) {
				if (newValue && newValue.length > 0) {
					try {
						debounce(await this.$store.dispatch('order/getNewPrices', { data: newValue }), 200)
					} catch (error) {
						if (error) console.error(error)
					}
				}
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.a-add-student-button
	.nav-link
		background-color: #6CBD7A
		color: #fff
		border: 2px solid #6CBD7A

.z-submit-btn
	border-radius: 2px
.price-loading
	position: relative

	.sticky-top
		&:after
			content: ""
			width: 100%
			height: 100%
			background: #fafaf8
			opacity: .6
			position: absolute
			left: 0
			top: 0
			z-index: 999

	.price-spinner
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50% -50%)
		z-index: 9999

.fade-in
	visibility: visible !important
	opacity: 1 !important
</style>
